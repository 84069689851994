<template>
  <div class="app-container">
    <!-- 查询开始 -->
    <div><div class="addbutton"><el-button type="primary" round plain @click="addRole" icon="el-icon-circle-plus">创建</el-button></div>
      <!-- 弹框 -->
      <el-dialog title="创建角色" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="名称" :label-width="formLabelWidth"  prop="name"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'blur' }]">
            <el-input v-model="form.name" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="form.remark" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="CreateList" :disabled="addRoleButton">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查询结束 -->
    <!-- 表格开始 -->
    <div>
      <el-table
        border
         v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableDataList"
        style="width:100%;"
        max-height="650"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="350">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="350">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态
              " width="350">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.state }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="roleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" plain @click="roleDel(scope.row)">删除</el-button>
            <el-button size="mini" type="success" plain @click="roleEditPms(scope.row)">编辑权限</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 编辑员工开始 -->
      <el-dialog title="编辑角色" :visible.sync="DataEdits">
        <el-form :model="roleData">
          <el-form-item label="名称" :label-width="formLabelWidth"  prop="name"
            :rules="[{ required: true, message: '商户不能为空', trigger: 'blur' }]">
            <el-input v-model="roleData.name" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="roleData.remark" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="DataEdits = false">取 消</el-button>
          <el-button type="primary" @click="DetermineEdit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 编辑员工结束 -->
      <!-- 删除弹窗开始 -->
      <el-dialog title="删除" top="30vh" :visible.sync="dialogVisibleDelete" width="15%">
        <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleDelete = false">取 消</el-button>
          <el-button type="primary" @click="DelSure">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 删除弹窗结束 -->
      <!-- 编辑角色权限开始 -->
      <el-dialog title="编辑权限" :visible.sync="permissions" width="40%">
        <div
          style="width:90%;height:400px;border:2px solid #ccc;margin:30px auto 0 ;overflow-y: scroll;"
        >
          <div v-for="(item,index) in list" :key="index" style="margin-left:30px;margin-top:20px;">
            <el-checkbox
              v-model="item.roleAuthTb"
              :disabled="!item.userAuthTb"
              @change="fatherValue(item)"
            >{{item.theModule}}&nbsp;&nbsp;·························································</el-checkbox>
            <div v-for="(v,i) in item.cities" :key="i" style="margin-left:30px;">
              <el-checkbox
                v-model="v.roleAuthTb"
                :disabled="!v.userAuthTb"
                @change="sonValue(v,item)"
              >{{v.name}}</el-checkbox>
              <div style="display:flex;flex-wrap:wrap">
                <div v-for="(i,k) in v.arr" :key="k" style="margin-left:30px;">
                  <el-checkbox
                    v-model="i.roleAuthTb"
                    :disabled="!i.userAuthTb"
                    @change="changeValue(i,v,item)"
                  >{{i.name}}</el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="permissions = false">取 消</el-button>
          <el-button type="primary" @click="RoleAuthorEdit">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 编辑角色权限结束 -->
    </div>
    <!-- 表格结束 -->
    <!-- 分页开始 -->
    <div class="pag">
      <!-- <Paging /> -->
    </div>
    <!-- 分页结束 -->
  </div>
</template>

<script>
import Query from "../../components/Query/Query.vue";
import Paging from "../../components/Paging/Paging.vue";
import { filter } from "minimatch";
export default {
  components: {
    Query,
    Paging
  },
  data() {
    return {
      //搜索
      addRoleButton:false,
      loading:true,
      receivablesShow: false,
      input: "", //高级查询value值
      options: [
        {
          value: "选项1",
          label: "可用"
        },
        {
          value: "选项2",
          label: "不可用"
        }
      ],
      Numbering: "", //编号
      dialogFormVisible: false, //弹框值
      formLabelWidth: "80px", //弹框输入框宽度
      form: {
        //弹框表单
        name: "",
        remark: "无"
      },
      show: false, //高级搜索
      //搜索结束
      tableDataList: [],
      DataEdits: false, //编辑弹框
      roleData: {}, //编辑数据
      dialogVisibleDelete: false, //删除弹框
      rowData: {}, //删除数据

      //编辑权限
      permissions: false, //编辑权限弹框
      checkAll: false,
      list: [
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] },
        { theModule: "", checkedCities: [], checkAll: false, cities: [] }
      ],
      EdiId: "", //点击编辑权限的角色id
      roleList: [], //初始的角色权限
      newarr: []
    };
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    // 获取角色
    getEmployees() {
      this.$axios
        .post("/role/query", {
          pageConfig: {
            orderType: "desc",
            pageSize: "20",
            orderField: "id",
            pageNum: "1"
          }
        })
        .then(res => {
          this.loading=false;
          let datalist = res.data.roleTbPage; //返回数据
          let number = 0; //定义编号变量
          datalist.map(item => {
            number++;
            item.num = number;
            item.createTime = this.getTiem(item.createTime);
            if (item.state === 1) {
              item.state = "可用";
            } else {
              item.state = "禁用";
            }
            if (item.authorized === 1) {
              item.authorized = "是";
            } else {
              item.authorized = "否";
            }
            return item;
          });
          this.tableDataList = datalist;
        });
    },
    //编辑角色
    roleEdit(row) {
      this.DataEdits = true;
      this.roleData = { ...row };
    },

    // 确认编辑
    DetermineEdit() {
      const { name, remark, id } = this.roleData;
      if (name !== "") {
        this.$axios
          .get("/role/edit", { params: { roleName: name.trim(), remark, roleId: id } })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.getEmployees();
              this.DataEdits = false;
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请输入完整信息");
      }
    },
    // 创建角色
    addRole() {
      this.addRoleButton=false;
      this.dialogFormVisible = true;
    },
    // 确认创建
    CreateList() {
      this.addRoleButton=true;
      const { name, remark } = this.form;
      if (name !== "") {
        this.$axios
          .get("/role/create", { params: { roleName: name.trim(), remark } })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.getEmployees();
              this.dialogFormVisible = false;
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请输入完整信息");
      }
    },
    // 删除角色
    roleDel(row) {
      this.dialogVisibleDelete = true;
      this.rowData = row;
    },
    // 确认删除
    DelSure() {
      const { id } = this.rowData;
      this.$axios.get("/role/delete", { params: { roleId: id } }).then(res => {
        if (res.data.state === 1) {
          this.DeleteSuccess();
          this.getEmployees();
          this.dialogVisibleDelete = false;
        } else if (res.data.state === -2) {
          this.open4(res.data.state);
          this.dialogVisibleDelete = false;
        } else if (res.data.state === -1) {
          this.open4(res.data.state);
          this.dialogVisibleDelete = false;
        }
      });
    },

    //编辑权限
    roleEditPms(row) {
      this.roleList = [];
      this.EdiId = row.id;
      this.permissions = true;
      this.$axios
        .get("/role/queryAuth", { params: { roleId: this.EdiId } })
        .then(res => {
          const roleAuthTbCheckMap = res.data.roleAuthTbCheckMap; //待编辑角色拥有的权限
          const userAuthTbCheckMap = res.data.userAuthTbCheckMap; //当前操作用户拥有的权限
          // 清空功能
          this.list = [];
          // 找到第一层主模块
          res.data.authTbLevelPage.forEach(item => {
            if (item.level === 1) {
              //theModule:主模块名字，checkedCities;选中的模块，checkAll：主模块是否选中，cities：主模块对应的功能模块
              this.list.push({
                theModule: item.name,
                id: item.id,
                roleAuthTb: roleAuthTbCheckMap[item.id],
                userAuthTb: userAuthTbCheckMap[item.id],
                cities: [] //第二级
              });
            }
          });
          // 找到第二子模块，把子模块的pid与主模块的id对应
          res.data.authTbLevelPage.forEach(item => {
            if (item.level === 2) {
              this.list.map(v => {
                if (v.id === item.pid) {
                  //roleAuthTb：待编辑角色拥有的权限。userAuthTb：当前操作用户拥有的权限
                  v.cities.push({
                    name: item.name,
                    id: item.id,
                    roleAuthTb: roleAuthTbCheckMap[item.id],
                    userAuthTb: userAuthTbCheckMap[item.id],
                    arr: [] //第三级
                  });
                }
              });
            }
          });
          // 找到第三次子模块，把子模块的pid与主模块的id对应
          res.data.authTbLevelPage.forEach(item => {
            if (item.level === 3) {
              this.list.map(v => {
                v.cities.forEach(j => {
                  if (j.id === item.pid) {
                    //roleAuthTb：待编辑角色拥有的权限。userAuthTb：当前操作用户拥有的权限
                    j.arr.push({
                      name: item.name,
                      id: item.id,
                      roleAuthTb: roleAuthTbCheckMap[item.id],
                      userAuthTb: userAuthTbCheckMap[item.id]
                    });
                  }
                });
              });
            }
          });
          // 保存第一层的权限状态
          this.newList = this.list.map(item => {
            return { ...item };
          });
          //  保存第二层的权限状态
          this.newarr = [];
          this.list.forEach(item => {
            item.cities.map(v => {
              this.newarr.push({ ...v });
            });
          });
          // 保存第三层最初的权限状态
          let arr = new Array();
          this.list.forEach(item => {
            item.cities.forEach(v => {
              v.arr.forEach(i => {
                arr.push({ ...i });
              });
            });
          });
          this.roleList = [arr.reverse()];
          this.list.reverse();
        });
    },
    // 确认编辑
    RoleAuthorEdit() {
      // 提交时的权限状态
      let arr2 = [],
        arr3 = [], //增加的权限
        arr4 = []; //减少的权限
      // 提交时的所有第三层
      this.list.forEach(item => {
        item.cities.forEach(v => {
          v.arr.forEach(i => {
            arr2.push(i);
          });
        });
      });
      // 所有增加的第三层
      arr2.forEach(item => {
        this.roleList[0].forEach(v => {
          if (item.id == v.id && item.roleAuthTb !== v.roleAuthTb) {
            if (item.roleAuthTb === true) {
              arr3.push(item);
            } else {
              arr4.push(item);
            }
          }
        });
      });
      // 所有减少的第三层
      this.list.forEach(item => {
        item.cities.forEach(v => {
          v.arr.forEach(j => {
            if (j.roleAuthTb === true) {
            }
          });
        });
      });
      // 增减的数据
      let object = {};
      arr3.forEach(i => {
        object[i.id] = 1;
      });
      arr4.forEach(i => {
        object[i.id] = -1;
      });
      //如果第三层选中，第二层，第一层也应选中
      this.newList.forEach(item => {
        this.newarr.forEach(v => {
          v.arr.forEach(i => {
            arr3.forEach(j => {
              if (i.id === j.id) {
                if (v.roleAuthTb === false) {
                  object[v.id] = 1;
                }
              }
            });
          });
        });
      });
      // 只选中第一层
      this.newList.forEach(item => {
        this.list.forEach(v => {
          if (v.id === item.id) {
            if (v.roleAuthTb !== item.roleAuthTb) {
              if (v.roleAuthTb === true) {
                object[v.id] = 1;
              } else {
                object[v.id] = -1;
              }
            }
          }
        });
      });
      // 当第一层不选中时，第二层，第三层都不选中
      this.list.forEach(item => {
        if (item.roleAuthTb === false) {
          item.cities.forEach(v => {
            // 第二层选中的取消
            object[v.id] = -1;
            // 第三层选中的取消
            v.arr.forEach(i => {
              object[v.id] = -1;
            });
          });
        }
      });
      // 当第二层选中时
      this.list.forEach(item => {
        item.cities.forEach(v => {
          this.newarr.forEach(i => {
            if (i.id === v.id && i.roleAuthTb !== v.roleAuthTb) {
              if (v.roleAuthTb === true) {
                object[v.id] = 1;
              } else {
                object[v.id] = -1;
              }
            }
          });
        });
      });
      this.$axios
        .post("/role/editAuth", { roleId: this.EdiId, authIdMap: object })
        .then(res => {
          if (res.data.state == 1) {
            this.DeleteSuccess();
            this.permissions = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },

    //一级模块功能
    fatherValue(item) {
      if (item.roleAuthTb === true) {//第一层选中时子菜单全部选中
        item.cities.forEach(v => {
          v.roleAuthTb = true;
          v.arr.forEach(i => {
            i.roleAuthTb = true;
          });
        });
      }
      if (item.roleAuthTb === false) {// 第一级未选中时，下面两个子模块都不选中
        item.cities.forEach(v => {
          v.roleAuthTb = false;
          v.arr.forEach(i => {
            i.roleAuthTb = false;
          });
        });
      }
    },
    // 第二级选中时，第一级选中
    sonValue(v, item) {
      if (v.roleAuthTb === true) {
        item.roleAuthTb = true;
      }
      if (v.roleAuthTb === false) {
        v.arr.forEach(j => {
          j.roleAuthTb = false;
        });
      }
    },
    // 第三级选中时，前面两级都选中
    changeValue(i, v, item) {
      if (i.roleAuthTb === true) {
        v.roleAuthTb = true;
        item.roleAuthTb = true;
      }
    },

    // 主模块
    handleCheckAllChange(val) {
      console.log(val, "11");

      // 主模块未选中时，子功能模块不能选中
      if (val.checkAll === false) {
        val.checkedCities = [];
      }
      // 主要模块选中时，底部功能默认全选
      // if(val.checkAll==true){
      //   val.checkedCities=val.cities;
      // }
    },
    // 子功能
    handleCheckedCitiesChange(val) {
      if (val.checkedCities.length !== 0) {
        val.checkAll = true;
      }
    },

    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    //成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },

    //提示信息
    open4(data) {
      this.$notify.error({
        title: "错误",
        message:
          data == -2 ? "不能删除正在被使用的角色！" : "不能删除自身所在角色"
      });
    }
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.pag {
  margin-top: 10px;
  text-align: right;
  padding-right: 50px;
}
.el-table .cell {
  text-align: center;
}
/* 高级搜索开始 */
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 50px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.pag {
  position: fixed;
  bottom: 10px;
  right: 0;
}


/* 高级搜索结束 */
.el-button.is-round {
  padding: 10px 10px;
}
.div {
  width: 90%;
  height: 400px;
  margin: auto;
  background-color: #fbfeff;
  padding: 30px;
  border: 1px solid #ccc;
  overflow-y: scroll;
}

.el-col-4 {
  width: 13.66667%;
}
.el-button.is-round {
  padding: 10px 25px;
}
.el-table td {
  padding: 10px 0;
}
.addbutton{
  text-align: right;
  margin-bottom: 20px;
}
</style>